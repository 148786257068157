import { ActionMenuItem, ActionMenuItemProps } from '@systemeio/ui-shared'
import React from 'react'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'

interface ActionMenuLinkItemProps extends Omit<ActionMenuItemProps, 'onClick'> {
  isNext?: boolean
  href: string
  openInNew?: boolean
  dataTestAttributes?: Record<string, string>
  onClick?: ActionMenuItemProps['onClick']
}

export const ActionMenuLinkItemNew = ({
  label,
  isNext,
  href,
  openInNew,
  dataTestAttributes,
  onClick = () => {},
  ...props
}: ActionMenuLinkItemProps) => (
  <ActionMenuItem
    key={'action-edit'}
    label={
      isNext ? (
        <LinkWithoutPrefetch type="button" href={href} {...dataTestAttributes}>
          <span>{label}</span>
        </LinkWithoutPrefetch>
      ) : (
        <a
          type="button"
          href={href}
          target={openInNew ? '_blank' : '_self'}
          rel={'noreferrer'}
          {...dataTestAttributes}
        >
          <span>{label}</span>
        </a>
      )
    }
    onClick={onClick}
    {...props}
  />
)
